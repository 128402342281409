/**
 * External dependencies
 */
import { FC } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';

export type LabelProps = {
	content: string;
	color: string;
};

const Label: FC<LabelProps> = ({ content, color }) => (
	<div className={classnames('label', `bg:${color}`)}>
		<span className="style:label color:gray99">{content}</span>
	</div>
);

export default Label;
