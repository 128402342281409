/**
 * External dependencies
 */
import { FC } from 'react';
import { filter, isEmpty } from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import Helmet from 'react-helmet';

/**
 * Internal dependencies
 */
import './styles.scss';
import { buttons, links as headerLinks } from 'data/headerData.json';
import { copyright, links as footerLinks } from 'data/footerData.json';
import { Header, Footer } from 'components';
import { PageProps, SiteMetadataQuery } from 'types';

const MainLayout: FC<PageProps> = ({ children, pageContext, data }) => {
	const { site, mdx, featuredImage }: SiteMetadataQuery =
		useStaticQuery(graphql`
			query SiteMetadata {
				site {
					siteMetadata {
						description
						siteUrl
						title
						locale
					}
				}
				mdx(slug: { eq: "404" }) {
					frontmatter {
						title
					}
				}
				featuredImage: file(relativePath: { eq: "og-image.png" }) {
					childImageSharp {
						gatsbyImageData(layout: FIXED, width: 1200)
					}
				}
			}
		`);

	const frontmatter = pageContext.frontmatter || mdx?.frontmatter;

	const siteTitle = site?.siteMetadata?.title || '';

	const titleParts = filter(
		[frontmatter?.title, siteTitle],
		(value) => !isEmpty(value)
	);

	const title = titleParts.join(' - ');

	const pageImage = data?.featuredImage?.childImageSharp?.gatsbyImageData;
	const globalImage = featuredImage?.childImageSharp?.gatsbyImageData as
		| IGatsbyImageData
		| undefined;

	const imageData = pageImage || globalImage;

	const siteUrl = site?.siteMetadata?.siteUrl || undefined;

	const metas = filter(
		[
			['og:locale', site?.siteMetadata?.locale || undefined],
			['og:url', siteUrl],
			[
				'og:image',
				imageData && siteUrl
					? siteUrl + imageData.images.fallback?.src
					: undefined,
			],
			[
				'og:image:width',
				imageData ? imageData.width.toString() : undefined,
			],
			[
				'og:image:height',
				imageData ? imageData.height.toString() : undefined,
			],
			['description', site?.siteMetadata?.description || undefined],
			['og:type', 'website'],
			['og:title', title],
		],
		(item) => !!item[1]
	);

	return (
		<>
			<Helmet>
				<title>{title}</title>
				{metas.map(([key, value]) => (
					<meta property={key} content={value} />
				))}
			</Helmet>
			<div className="main-layout">
				<Header links={headerLinks} buttons={buttons} />
				<div className="site-content">{children}</div>
				<Footer copyright={copyright} links={footerLinks} />
			</div>
		</>
	);
};

export const imageDataFragment = graphql`
	fragment imageData on File {
		childImageSharp {
			gatsbyImageData
		}
		svg {
			content
		}
	}
`;

export default MainLayout;
