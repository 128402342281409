import { graphql } from 'gatsby';
import { Button, Content, Spacer, Image } from 'components';
import image404 from 'images/404.svg';
import * as React from 'react';
export default {
  graphql,
  Button,
  Content,
  Spacer,
  Image,
  image404,
  React
};