/**
 * External dependencies
 */
import 'swiper/css';
import { FC, useState, useEffect, useRef } from 'react';
import { Pagination } from 'swiper';
import { renderToStaticMarkup } from 'react-dom/server';
import { Swiper, SwiperSlide } from 'swiper/react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';
import { ImageData } from 'types';
import { useMatchBreakpoint } from 'hooks';
import Image from 'components/Image';

type SliderProps = {
	slides: Array<{
		color: string;
		image: ImageData;
		heading: string;
		description: string;
	}>;
};

const Slider: FC<SliderProps> = ({ slides }) => {
	const [currentSlide, setCurrentSlide] = useState(0);

	const paginationElement = useRef<HTMLElement>();
	const bulletElement = useRef<HTMLElement>();

	const isSmallScreen = useMatchBreakpoint('sm');

	const renderBullet = (index: number, className: string) => {
		const { color, heading, description } = slides[index];

		return renderToStaticMarkup(
			<div className={className}>
				<div
					className={classnames(
						'swiper-pagination-bar',
						`bg:${color}`
					)}
				/>
				<div className="swiper-pagination-bullet-content">
					<p className="swiper-pagination-heading color:gray00">
						{heading}
					</p>
					<span className="color:gray40 style:p">{description}</span>
				</div>
			</div>
		);
	};

	useEffect(() => {
		const handleBulletsPosition = () => {
			if (!paginationElement.current || !bulletElement.current) {
				return;
			}

			const bulletWidth = bulletElement.current.offsetWidth;
			const paginationWidth = paginationElement.current.offsetWidth;
			const isLast = currentSlide === slides.length - 1;

			const offset =
				!isSmallScreen || currentSlide === 0
					? 0
					: isLast
					? paginationWidth - bulletWidth * slides.length
					: currentSlide * bulletWidth * -1 +
					  (paginationWidth - bulletWidth) / 2;

			paginationElement.current.style.transform = `translateX(${offset}px)`;
		};

		handleBulletsPosition();

		window.addEventListener('resize', handleBulletsPosition);

		return () =>
			window.removeEventListener('resize', handleBulletsPosition);
	}, [currentSlide, slides, isSmallScreen]);

	return (
		<div className="swiper-outer-wrapper">
			<Swiper
				pagination={{
					clickable: true,
					renderBullet,
				}}
				modules={[Pagination]}
				onSlideChange={(slide) => setCurrentSlide(slide.realIndex)}
				onInit={(swiper) => {
					paginationElement.current =
						swiper.el.querySelector<HTMLElement>(
							'.swiper-pagination'
						) || undefined;

					bulletElement.current =
						swiper.el.querySelector<HTMLElement>(
							'.swiper-pagination-bullet'
						) || undefined;
				}}
			>
				{slides.map(({ color, image }, index) => (
					<SwiperSlide
						className={classnames('slide', `bg:${color}`, {
							current: currentSlide === index,
							prev: currentSlide > index,
						})}
						key={index}
					>
						<Image {...image} className="swiper-slide-image" />
					</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
};

export default Slider;
