/**
 * External dependencies
 */
import { FC, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Link as LinkType } from 'types';
import { ReactComponent as Logo } from 'images/logo.svg';
import Button from 'components/Button';

type HeaderProps = {
	links: Array<LinkType>;
	buttons: {
		mobile: LinkType;
		desktop: LinkType;
	};
};

const Header: FC<HeaderProps> = ({ links, buttons }) => {
	const [isHidden, setIsHidden] = useState(false);
	const prevScroll = useRef(0);

	useEffect(() => {
		const handleScrollDirection = () => {
			setIsHidden(prevScroll.current < window.pageYOffset);

			prevScroll.current = window.pageYOffset;
		};

		window.addEventListener('scroll', handleScrollDirection);

		return () =>
			window.removeEventListener('scroll', handleScrollDirection);
	}, []);

	return (
		<header
			className={classNames('header bg:gray70', {
				'is-hidden': isHidden,
			})}
		>
			<Link to="/" className="header-logo-link">
				<Logo />
			</Link>
			<ul className="header-links">
				{links.map((link, index) => (
					<li className="header-links-element" key={index}>
						<Link to={link.url} className="header-link">
							{link.label}
						</Link>
					</li>
				))}
			</ul>
			<div className="header-button-mobile-only">
				<Button to={buttons.mobile.url} type="small" color="berry80">
					{buttons.mobile.label}
				</Button>
			</div>
			<div className="header-button-desktop-only">
				<Button to={buttons.desktop.url} type="small" color="berry80">
					{buttons.desktop.label}
				</Button>
			</div>
		</header>
	);
};

export default Header;
