/**
 * Internal dependencies
 */
import useMatchMedia from './useMatchMedia';
import * as breakpoints from '../scss/utils/_breakpoints.module.scss';

const useMatchBreakpoint = (breakpoint: string) =>
	useMatchMedia(
		`(max-width: ${breakpoints[breakpoint as keyof typeof breakpoints]})`
	);

export default useMatchBreakpoint;
