/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import HeadingHighlight from 'components/HeadingHighlight';
import { ReactComponent as HandSVG } from 'images/heading-with-images/hand.svg';
import { ReactComponent as PenSVG } from 'images/heading-with-images/pen.svg';

type HeadingWithImagesProps = {
	/**
	 * You can use pre-definied colors from figma palette, or from _variables.scss
	 */
	color: string;
	description: string;
	/**
	 * Remember to use the same syntax as in HeadingHightlight component, like `Hightlight *this*`
	 */
	heading: string;
};

const HeadingWithImages: FC<HeadingWithImagesProps> = ({
	color,
	description,
	heading,
}) => (
	<div className="heading-with-images">
		<div className="heading-with-images-images">
			<HandSVG />
			<PenSVG className="heading-with-images-pen-svg" />
		</div>
		<div className="heading-with-images-text-content">
			<HeadingHighlight color={color}>{heading}</HeadingHighlight>
			<p className="heading-with-images-description style:pfeatured">
				{description}
			</p>
		</div>
	</div>
);

export default HeadingWithImages;
