/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Image } from 'components';
import { ImageData } from 'types';

export type CardProps = {
	image: ImageData;
	title: string;
	description: string;
};

const Card: FC<CardProps> = ({ image, title, description }) => (
	<div className="card">
		<Image className="card-image" {...image} />
		<h3 className="card-title style:h2 color:gray00">{title}</h3>
		<p className="card-description style:p color:gray40">{description}</p>
	</div>
);

export default Card;
