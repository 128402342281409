/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import Copyright, { CopyrightProps } from './Copyright';
import FooterLinks from './FooterLinks';
import Logo from './Logo';
import { Link } from 'types';

type FooterProps = {
	copyright: CopyrightProps;
	links: Array<Link>;
};

const Footer: FC<FooterProps> = ({ copyright, links }) => (
	<footer className="site-footer bg:gray99">
		<div className="site-footer-content">
			<Logo />
			<FooterLinks links={links} />
		</div>
		<Copyright link={copyright.link} text={copyright.text} />
	</footer>
);

export default Footer;
