/* eslint-disable react/no-danger */

/**
 * Externla dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import { ReactComponent as HandsSVG } from 'images/image-box/hands.svg';

type ImageBoxProps = {
	/**
	 * You can add '|' character to insert a line break
	 */
	children: string;
};

const ImageBox: FC<ImageBoxProps> = ({ children }) => (
	<div className="image-box">
		<div className="image-box-inner">
			<p
				className="image-box-title color:gray99"
				dangerouslySetInnerHTML={{
					__html: children.replaceAll('|', '<br />'),
				}}
			/>
			<HandsSVG className="image-box-hands" />
		</div>
	</div>
);

export default ImageBox;
