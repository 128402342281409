/**
 * External dependencies
 */
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Position } from 'types';
import { useBreakpointDownValue } from 'hooks';

export interface LayeredImageProps {
	backgroundImage: string;
	color: string;
	foregroundImage?: string;
	/**
	 * Object containing percentage x and y offset values relative to foreground image width and height respectively.
	 */
	foregroundOffset: Position;
	position: 'left' | 'right';
	/**
	 * Responsive offset values.
	 */
	responsiveForegroundOffset: Record<string, Position>;
	/**
	 * Determines the foreground image's alignment anchor. Default `top-left` means, that with offset set to `0` the top
	 * left corner of the foreground image will be aligned with the top left corner of a background image. `bottom-right`
	 * on the other hand means, that the bottom right corners of both images will be aligned, etc.
	 */
	foregroundAlign?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
	/**
	 * Percentage foreground image width relative to the background width.
	 */
	foregroundWidth?: number;
	/**
	 * Minimal foreground image width in piksels.
	 */
	foregroundMinWidth?: number;
}

const LayeredImage: FC<LayeredImageProps> = ({
	backgroundImage,
	color,
	foregroundAlign = 'top-left',
	foregroundImage,
	foregroundMinWidth,
	foregroundOffset,
	foregroundWidth,
	position,
	responsiveForegroundOffset,
}) => {
	const ref = useRef<HTMLDivElement>(null);
	const imgRef = useRef<HTMLImageElement>(null);

	const [padding, setPadding] = useState<[number, number]>([0, 0]);

	const offset =
		useBreakpointDownValue(responsiveForegroundOffset) || foregroundOffset;

	const adjust = useCallback(() => {
		if (!ref.current || !imgRef.current) {
			return;
		}

		const mainRect = ref.current.getBoundingClientRect();
		const imgRect = imgRef.current.getBoundingClientRect();
		const paddingTop = parseFloat(ref.current.style.paddingTop);
		const paddingBottom = parseFloat(ref.current.style.paddingBottom);

		setPadding([
			Math.max(Math.round(mainRect.top + paddingTop - imgRect.top), 0),
			Math.max(
				Math.round(
					imgRect.top -
						mainRect.top +
						imgRef.current.offsetHeight -
						ref.current.offsetHeight +
						paddingBottom
				),
				0
			),
		]);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			window.requestAnimationFrame(() => {
				adjust();
			});
		}, 0);

		window.addEventListener('resize', adjust);

		return () => window.removeEventListener('resize', adjust);
	}, [adjust]);

	return (
		<div
			className={classnames('layered-image', position)}
			ref={ref}
			style={{
				paddingTop: `${padding[0]}px`,
				paddingBottom: `${padding[1]}px`,
			}}
		>
			<div className={classnames('layered-image-inner', `bg:${color}`)}>
				<div className="layered-image-background">
					<img alt="" onLoad={adjust} src={backgroundImage} />
				</div>
				{foregroundImage && (
					<img
						alt=""
						className={classnames(
							'layered-image-foreground',
							foregroundAlign.split('-')
						)}
						onLoad={adjust}
						ref={imgRef}
						src={foregroundImage}
						style={{
							transform: `translate(${offset.x}%, ${offset.y}%)`,
							...((foregroundWidth && {
								width: `${foregroundWidth}%`,
							}) ||
								undefined),
							...((foregroundMinWidth && {
								minWidth: `${foregroundMinWidth / 160}rem`,
							}) ||
								undefined),
						}}
					/>
				)}
			</div>
		</div>
	);
};

export default LayeredImage;
