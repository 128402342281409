/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Image } from 'components';
import { ImageData } from 'types';
import { ReactComponent as OpinionSignature } from 'images/opinion-box/signature.svg';

export type OpinionTypes = {
	description: string | Array<string>;
	avatar: ImageData;
	personName: string;
	personTitle: string;
};

const OpinionBox: FC<OpinionTypes> = ({
	description,
	avatar,
	personName,
	personTitle,
}) => {
	const lines = typeof description === 'string' ? [description] : description;

	return (
		<div className="opinion bg:gray75">
			<div className="opinion-description color:gray30">
				{lines.map((line) => (
					<p key={line}>{line}</p>
				))}
			</div>
			<div className="opinion-person-wrapper">
				<Image className="opinion-person-avatar" {...avatar} />
				<div className="opinion-person-data">
					<p className="opinion-person-name color:banana99">
						{personName}
					</p>
					<span className="color:gray40">{personTitle}</span>
				</div>
			</div>
			<div className="opinion-signature-wrapper">
				<OpinionSignature className="opinion-signature" />
			</div>
		</div>
	);
};

export default OpinionBox;
