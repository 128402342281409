import { graphql } from 'gatsby';
import { AdvantagesList, Background, BenefitsList, Button, Card, Container, CTABox, ExtensionInfo, HeadingHighlight, HeadingWithImages, HeroMedia, ImageBox, Label, LayeredImage, Layout, OfferBox, OpinionBox, Price, Slider, Spacer } from 'components';
import big1 from 'images/layered-images/big1.svg';
import big2 from 'images/layered-images/big2.svg';
import big3 from 'images/layered-images/big3.svg';
import small1 from 'images/layered-images/small1.svg';
import small2 from 'images/layered-images/small2.svg';
import small3 from 'images/layered-images/small3.svg';
import { DocsIcon, FormattingIcon, PrivacyIcon, SecurityIcon, SynchronizationIcon, ThemeIcon } from 'images';
import videoPreview from 'images/promo-preview.gif';
import * as React from 'react';
export default {
  graphql,
  AdvantagesList,
  Background,
  BenefitsList,
  Button,
  Card,
  Container,
  CTABox,
  ExtensionInfo,
  HeadingHighlight,
  HeadingWithImages,
  HeroMedia,
  ImageBox,
  Label,
  LayeredImage,
  Layout,
  OfferBox,
  OpinionBox,
  Price,
  Slider,
  Spacer,
  big1,
  big2,
  big3,
  small1,
  small2,
  small3,
  DocsIcon,
  FormattingIcon,
  PrivacyIcon,
  SecurityIcon,
  SynchronizationIcon,
  ThemeIcon,
  videoPreview,
  React
};