/**
 * Converts pixel value to rem.
 *
 * @param  value Pixel value.
 * @return Calculated rem value with unit.
 */
export const pxToRem = (value: number | string, base: number = 16): number => {
	const parsed = Math.floor(
		typeof value === 'number' ? value : parseInt(value, 10)
	);

	return parsed ? parsed / base : 0;
};

export const remToPx = (value: string | number, base: number = 16): number => {
	const remInt = typeof value === 'string' ? parseFloat(value) : value;

	return remInt * base;
};
