/**
 * External dependencies
 */
import { FC, PropsWithChildren, ReactElement } from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';
import { ReactComponent as ArrowLeft } from 'images/button/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'images/button/arrow-right.svg';

export type ButtonProps = PropsWithChildren<{
	arrow?: 'left' | 'right';
	color?: string;
	icon?: ReactElement;
	to: string;
	type: 'ghost' | 'plain' | 'plain-block' | 'text' | 'small';
	fbq?: [string, string, Record<string, any>];
	gtag?: Parameters<Gtag.Gtag>;
}>;

const Button: FC<ButtonProps> = ({
	arrow,
	children,
	color,
	icon,
	to,
	gtag: gtagParams,
	type,
}) => {
	const isText = ['text', 'ghost'].includes(type);

	if (type === 'text' && !arrow) {
		arrow = 'right';
	}

	const handleClick = () => {
		if (gtagParams && typeof window !== undefined && window.gtag) {
			window.gtag(...gtagParams);
		}
	};

	return (
		<Link
			className={classnames(
				'button',
				`button-${type}`,
				`color:${color}`,
				{
					extension: icon,
					[`bg:${color}`]: !isText,
					'style:textbutton': type === 'text',
					'style:button': type !== 'text' && type !== 'small',
					'style:button-small': type === 'small',
				}
			)}
			to={to}
			onClick={handleClick}
		>
			{icon && icon}
			<span
				className={classnames({
					'color:gray00': !isText,
				})}
			>
				{arrow === 'left' && (
					<ArrowLeft className="button-arrow-left" />
				)}
				{children}
				{arrow === 'right' && (
					<ArrowRight className="button-arrow-right" />
				)}
			</span>
		</Link>
	);
};

export default Button;
