/**
 * External dependencies
 */
import { FC } from 'react';
import classnames from 'classnames';
import parser from 'html-react-parser';

/**
 * Internal dependencies
 */
import './styles.scss';

export type HeadingHighlightProps = {
	children: string;
	color?: string;
	isCentered?: boolean;
};

const HeadingHighlight: FC<HeadingHighlightProps> = ({
	children,
	color = 'banana99',
	isCentered = false,
}) => (
	<h2
		className={classnames('style:h1', 'color:gray00', 'heading-highlight', {
			'is-centered': isCentered,
		})}
	>
		{parser(
			children
				.replace(
					/\*([^*]+)\*/g,
					`<span className="color:${color}">$1</span>`
				)
				.replaceAll('|', '<br />')
		)}
	</h2>
);

export default HeadingHighlight;
