/**
 * External dependencies
 */
import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';

type ContentProps = PropsWithChildren<{
	isCentered?: boolean;
}>;

const Content: FC<ContentProps> = ({ children, isCentered = false }) => (
	<div
		className={classNames('content', 'is-narrow', {
			'is-centered': isCentered,
		})}
	>
		{children}
	</div>
);

export default Content;
