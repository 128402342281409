/**
 * External dependencies
 */
import { FC, useState } from 'react';
import classnames from 'classnames';
import ReactPlayer from 'react-player';

/**
 * Internal dependencies
 */
import { ReactComponent as Play } from 'images/play.svg';
import './styles.scss';

type VideoProps = {
	preview?: string;
	src: string;
};

const Video: FC<VideoProps> = ({ preview, src }) => {
	const [isPlaying, setIsPlaying] = useState(false);

	return (
		<div className="video">
			<ReactPlayer
				url={src}
				playing={isPlaying}
				onEnded={() => setIsPlaying(false)}
				width="100%"
				height="100%"
			/>
			{preview && (
				<button
					className={classnames('video-button', {
						'is-playing': isPlaying,
					})}
					onClick={() => setIsPlaying(true)}
				>
					<Play className="play-icon" />
					<img className="video-preview" src={preview} alt="" />
				</button>
			)}
		</div>
	);
};

export default Video;
