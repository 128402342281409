/**
 * External dependencies
 */
import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';

type ContainerProps = PropsWithChildren<{
	position: 'relative' | 'absolute' | 'initial';
}>;

const Container: FC<ContainerProps> = ({ children, position = 'initial' }) => (
	<div className={classnames('container', `container-${position}`)}>
		{children}
	</div>
);

export default Container;
