/**
 * External dependencies
 */
import { FC, ReactElement } from 'react';
import classnames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';
import { pxToRem } from 'utils';
import { Tuple } from 'types';
import { useBreakpointDownValue, useIsMounted } from 'hooks';

type LayoutPropsBase<N extends number> = {
	children: Tuple<ReactElement, N>;
	/**
	 * Array of percentage values
	 */
	itemWidths?: Tuple<number, N>;
	/**
	 * Array of pixel values (or null)
	 */
	itemMaxWidths?: Tuple<number | null, N>;
	verticalAlign?: 'center' | 'stretch' | 'reverse';
	verticalAlignResponsive?: 'reverse' | undefined;
};

type LayoutProps = (LayoutPropsBase<2> | LayoutPropsBase<3>) & {
	gap?: number;
	responsiveGap?: Record<string, number>;
};

const Layout: FC<LayoutProps> = ({
	children,
	gap,
	itemMaxWidths,
	itemWidths,
	responsiveGap,
	verticalAlign = 'center',
	verticalAlignResponsive,
}) => {
	const responsiveValue = useBreakpointDownValue(responsiveGap);

	if (responsiveValue) {
		gap = responsiveValue;
	}

	return (
		<div
			className={classnames(
				'layout',
				`layout-align-${verticalAlign}`,
				verticalAlignResponsive &&
					`layout-align-${verticalAlignResponsive}`
			)}
			style={gap ? { gap: `${pxToRem(gap)}rem` } : undefined}
		>
			{children.map((child, index) => (
				<div
					className="layout-item"
					key={index}
					style={{
						width: `${
							itemWidths
								? itemWidths[index]
								: 100 / children.length
						}%`,
						...(itemMaxWidths && itemMaxWidths[index] !== null
							? {
									maxWidth: `${
										itemMaxWidths[index]! / 16
									}rem`,
							  }
							: undefined),
					}}
				>
					{child}
				</div>
			))}
		</div>
	);
};

export default Layout;
