/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Background, Image, Video } from 'components';
import { ImageData } from 'types';

export type PropsWithVideoUrl = {
	image?: never;
	preview?: string;
	videoUrl: string;
};

export type PropsWithImageSrc = {
	image: ImageData;
	preview?: never;
	videoUrl?: never;
};

type HeroMediaProps = PropsWithImageSrc | PropsWithVideoUrl;

const HeroMedia: FC<HeroMediaProps> = ({ image, preview, videoUrl }) => (
	<div className="hero-media">
		{image && <Image alt="" className="hero-media-image" {...image} />}
		{videoUrl && (
			<div className="hero-media-video">
				<Video src={videoUrl} preview={preview} />
			</div>
		)}
		<Background
			direction="fade-to-bottom"
			position="center"
			size="medium"
			offset={{ x: 20, y: -130 }}
			responsiveOffset={{
				lg: { x: 0, y: -120 },
				md: { x: 0, y: -145 },
				sm: { x: 0, y: -185 },
				xs: { x: 0, y: -240 },
			}}
		/>
	</div>
);

export default HeroMedia;
