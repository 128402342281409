/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Position } from 'types';
import { ReactComponent as Ellipse } from 'images/ellipse.svg';
import { useBreakpointDownValue } from 'hooks';

interface BackgroundProps {
	direction:
		| 'fade-to-left'
		| 'fade-to-right'
		| 'fade-to-bottom'
		| 'fade-to-top';
	position: 'center' | 'center-left' | 'center-right';
	responsiveOffset?: Record<string, Position>;
	size: 'large' | 'medium' | 'small';
	offset?: Position;
}

const Background: FC<BackgroundProps> = ({
	direction,
	position,
	responsiveOffset = {},
	size,
	offset = { x: 0, y: 0 },
}) => {
	const responsiveValue = useBreakpointDownValue(responsiveOffset) || offset;

	return (
		<div
			className={`background ${size} ${position} ${direction}`}
			style={{
				'--offset-x': `${responsiveValue.x}px`,
				'--offset-y': `${responsiveValue.y}px`,
			}}
		>
			<Ellipse className="background-image" />
		</div>
	);
};

export default Background;
