/**
 * External dependencies
 */
import { FC } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Link } from 'types';

export type CopyrightProps = {
	link: Link;
	text: string;
};

const Copyright: FC<CopyrightProps> = ({ link, text }) => (
	<p className="copyright color:gray50">
		{/* eslint-disable react/no-danger */}
		<span
			dangerouslySetInnerHTML={{
				__html: text.replace(
					'%year%',
					new Date().getFullYear().toString()
				),
			}}
		/>
		{/* eslint-enable */}
		<a
			href={link.url}
			target="_blank"
			rel="noreferrer"
			className="color:gray30"
		>
			{link.label}
		</a>
	</p>
);

export default Copyright;
