/**
 * External dependencies
 */
import { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import parser from 'html-react-parser';

/**
 * Internal dependencies
 */
import { ImageData } from 'types';

type ImageProps = ImageData & {
	alt?: string;
	className?: string;
};

const Image: FC<ImageProps> = ({ alt, childImageSharp, className, svg }) => {
	if (svg && svg.content) {
		return <>{parser(svg.content)}</>;
	}

	if (childImageSharp && childImageSharp.gatsbyImageData) {
		return (
			<GatsbyImage
				image={childImageSharp.gatsbyImageData}
				alt={alt || ''}
				className={className}
			/>
		);
	}

	return null;
};

export default Image;
