/**
 * External dependencies
 */
import { FC, ReactElement } from 'react';
/**
 * Internal dependencies
 */
import './styles.scss';
import type { ButtonProps } from 'components/Button';
import type { LabelProps } from 'components/Label';
import type { PriceProps } from 'components/Price';
import type { ListPropTypes } from 'components/BenefitsList';
import { ReactComponent as ArrowSVG } from 'images/offer-box/arrow.svg';

type OfferBoxProps = {
	benefits: ReactElement<ListPropTypes>;
	button?: ReactElement<ButtonProps>;
	heading: {
		title: string;
		description: string;
	};
	label: ReactElement<LabelProps>;
	price?: ReactElement<PriceProps>;
};

const OfferBox: FC<OfferBoxProps> = ({
	benefits,
	button,
	heading,
	label,
	price,
}) => (
	<div className="offer-box bg:gray70">
		<div className="offer-box-text-content">
			{label}
			<div className="offer-box-header">
				<div className="offer-box-header-inner">
					<h3 className="offer-box-heading">{heading.title}</h3>
					<span className="offer-box-description style:p">
						{heading.description}
					</span>
				</div>
			</div>
			{benefits}
		</div>
		{price?.props.value !== 0 && (
			<div className="offer-box-price">{price}</div>
		)}
		<div className="offer-box-action-content">
			{button || <ArrowSVG className="offer-box-arrow" />}
		</div>
	</div>
);

export default OfferBox;
