/**
 * External dependencies
 */
import { chunk } from 'lodash';
import { FC } from 'react';
import { Link } from 'gatsby';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Link as LinkType } from 'types';

type FooterLinksProps = {
	columns?: number;
	links: Array<LinkType>;
};

const FooterLinks: FC<FooterLinksProps> = ({ columns = 2, links }) => (
	<div className="footer-links">
		{chunk(links, Math.ceil(links.length / columns)).map(
			(linksArray, index) => (
				<div className="footer-links-block" key={index}>
					{linksArray.map((link) => (
						<Link
							key={link.label}
							to={link.url}
							className="footer-link color:gray30"
						>
							{link.label}
						</Link>
					))}
				</div>
			)
		)}
	</div>
);

export default FooterLinks;
