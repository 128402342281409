/**
 * External dependencies
 */
import { ReactComponent as ListArrow } from 'images/benefits-list/arrow.svg';
import classnames from 'classnames';
import React, { FC } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';

export type ListPropTypes = {
	color: string;
	items: Array<string>;
	title: string;
};

const BenefitsList: FC<ListPropTypes> = ({ color, items, title }) => (
	<div className="benefits-list">
		<p className={classnames('benefits-list-title', `color:${color}`)}>
			{title}
		</p>
		{items.map((item, index) => (
			<div
				className={classnames('benefits-list-item', `color:${color}`)}
				key={index}
			>
				<ListArrow className="benefits-list-item-icon" />
				<span className="benefits-list-item-content color:gray00">
					{item}
				</span>
			</div>
		))}
	</div>
);

export default BenefitsList;
