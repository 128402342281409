/**
 * External dependencies
 */
import { FC } from 'react';
import { Link } from 'gatsby';

/**
 * Internal dependencies
 */
import './styles.scss';
import { ReactComponent as LogoImage } from 'images/logo.svg';

const Logo: FC = () => (
	<Link className="logo" to="/">
		<LogoImage />
	</Link>
);

export default Logo;
