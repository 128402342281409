/**
 * External dependencies
 */
import { ElementType, FC } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';

type AdvantagesListProps = {
	items: Array<{
		icon: ElementType;
		title: string;
		description: string;
	}>;
};

const AdvantagesList: FC<AdvantagesListProps> = ({ items }) => (
	<ul className="advantages">
		{items.map((item, index) => (
			<li className="advantage" key={index}>
				<item.icon />
				<div>
					<p className="advantage-title color:gray00 style:h2">
						{item.title}
					</p>
					<span className="advantage-description color:gray40">
						{item.description}
					</span>
				</div>
			</li>
		))}
	</ul>
);

export default AdvantagesList;
