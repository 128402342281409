/**
 * External dependencies
 */
import { FC, ReactElement, ReactNode } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import { Button } from 'components';
import {
	getCurrentExtensionInfo,
	getOtherExtensionsInfo,
} from 'utils/getExtensionInfo';
import { useIsMounted } from 'hooks';

type SpacerProps = {
	height: number;
	responsive?: Record<string, number>;
};

const ExtensionInfo: FC<SpacerProps> = ({ height, responsive }) => {
	const isMounted = useIsMounted();

	if (!isMounted) {
		return null;
	}

	const extensionInfo = getCurrentExtensionInfo();
	const otherExtensions = getOtherExtensionsInfo();

	return (
		<div className="extension-info">
			<div className="extension-info-buttons">
				<Button
					color="berry80"
					type="plain"
					arrow="right"
					to="https://use.madnotes.app"
				>
					Try Now
				</Button>
				{!!extensionInfo && (
					<>
						<p className="small-gray">or</p>
						<Button
							color="berry80"
							to={extensionInfo.link}
							type="ghost"
							icon={extensionInfo.icon || undefined}
						>
							Add to {extensionInfo.name}
						</Button>
					</>
				)}
			</div>
			<p className="extension-info-links">
				Available also for{' '}
				{otherExtensions.reduce(
					(prev, { link, name }, index) => [
						...prev,
						!!prev.length &&
							(index === otherExtensions.length - 1
								? ' and '
								: ', '),
						<a href={link} target="_blank">
							{name}
						</a>,
					],
					[] as ReactNode[]
				)}
			</p>
		</div>
	);
};

export default ExtensionInfo;
