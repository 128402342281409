/**
 * External dependencies
 */
import { detect } from 'detect-browser';

/**
 * Internal dependencies
 */
import { upperFirst } from 'lodash';
import extensions from 'data/extensions';

const browser = detect();

const browserNameMap: Record<string, string> = {
	'edge-chromium': 'edge',
};

const getSupportedBrowserName = () =>
	!browser || (!extensions[browser.name] && !browserNameMap[browser.name])
		? 'chrome'
		: browserNameMap[browser.name] || browser.name;

export const getOtherExtensionsInfo = () =>
	Object.entries(extensions)
		.filter(([browserName]) => browserName !== browser?.name)
		.filter(([browserName]) => browserName !== getSupportedBrowserName())
		.map(([browserName, data]) => ({
			...data,
			name: upperFirst(browserName),
		}));

export const getCurrentExtensionInfo = () => {
	const data = extensions[getSupportedBrowserName()];

	return {
		...data,
		name: upperFirst(getSupportedBrowserName()),
	};
};
