/**
 * External dependencies
 */
import classnames from 'classnames';
import { FC, ReactElement } from 'react';

/**
 * Internal dependencies
 */
import './styles.scss';
import type { ButtonProps } from 'components/Button';

type CTAProps = {
	children: ReactElement<ButtonProps> | Array<ReactElement<ButtonProps>>;
	color: string;
	heading: string;
	description: string;
};

const CTABox: FC<CTAProps> = ({ children, color, heading, description }) => (
	<div className={classnames('cta-box', `bg:${color}`)}>
		<div className="cta-box-text-content">
			<p className="cta-box-heading style:h1">{heading}</p>
			<p className="cta-box-description style:pfeatured">{description}</p>
		</div>
		<div className="cta-box-buttons">{children}</div>
	</div>
);

export default CTABox;
