/**
 * External dependencies
 */
import { FC } from 'react';
import classNames from 'classnames';

/**
 * Internal dependencies
 */
import './styles.scss';
import { ReactComponent as CrossoutSVG } from 'images/price/crossout.svg';

export type PriceProps = {
	color: string;
	value?: number;
	period?: string;
	annotation?: string;
	annotationColor: string;
	crossout: boolean;
};

const Price: FC<PriceProps> = ({
	value,
	period = 'month',
	annotation,
	color,
	crossout,
}) => {
	crossout = crossout !== undefined ? crossout : !!annotation;

	return (
		<div className="price">
			<div className="price-value-wrapper">
				{crossout && (
					<CrossoutSVG
						className={classNames(
							'price-crossout',
							`color:${color}`
						)}
					/>
				)}
				<span className="price-value color:gray00">
					{value ? `$${value}` : 'Free'}
				</span>
				{value && <span className="color:gray40">/{period}</span>}
			</div>
			{annotation && (
				<span
					className={classNames('price-annotation', `color:${color}`)}
				>
					{annotation}
				</span>
			)}
		</div>
	);
};

export default Price;
