/**
 * External dependencies
 */
import { findKey, mapValues } from 'lodash';

/**
 * Internal dependencies
 */
import useMatchMedia from './useMatchMedia';
import * as breakpoints from '../scss/utils/_breakpoints.module.scss';

const useBreakpoint = () => {
	const sortedBreakpoints = Object.fromEntries(
		Object.entries(breakpoints).sort(
			(a, b) => parseInt(a[1], 10) - parseInt(b[1], 10)
		)
	);

	const matchMedia = mapValues(sortedBreakpoints, (value) =>
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useMatchMedia(`(max-width: ${value})`)
	);

	return findKey(matchMedia, (value) => !!value);
};

export default useBreakpoint;
