/**
 * External dependencies
 */
import { ReactElement } from 'react';

/**
 * Internal dependencies
 */
import { ReactComponent as Chrome } from 'images/chrome.svg';
import { ReactComponent as Edge } from 'images/edge.svg';
import { ReactComponent as Firefox } from 'images/firefox.svg';

type ExtensionInfo = {
	link: string;
	icon: ReactElement;
};

const extensions: Record<string, ExtensionInfo> = {
	chrome: {
		link: 'https://chrome.google.com/webstore/detail/madnotes/fmjlhaobdnjacieihgolmlcenngjkibd',
		icon: <Chrome />,
	},
	edge: {
		link: 'https://microsoftedge.microsoft.com/addons/detail/madnotes/fagamnippkbgkbkindnpgfmpgdncgmjh',
		icon: <Edge />,
	},
	firefox: {
		link: 'https://addons.mozilla.org/en-US/firefox/addon/madnotes/',
		icon: <Firefox />,
	},
};

export default extensions;
